body {
  font-family: 'IBM Plex Sans';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.top-menu {
  margin: 10px 10px;
  align-items: center;
  justify-content: center;
}

.container {
  margin: 6rem 2rem;
  display: flex;
  flex-direction: row;
}

.home-image {
  width: 25%;
}

.content {
  width: 74%;
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url('images/round-rock.jpg');
  background-color: #282828;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {
  background-color: #282828;
  font-size: large;
  position: fixed;
  bottom: 0;
  font-weight: bold;
  padding-bottom: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
